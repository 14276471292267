<template>
	<div class="Brand">
		
		<div class="Top">
			<div class="Left">
				分组详情
			</div>
			<div class="Right">
				<span>
					<el-button type="primary" icon="el-icon-arrow-left" @click="$router.go(-1)">返回分组列表</el-button>
				</span>
			</div>
		</div>
		
		<div class="List">
			
			<div class="One">
				<li v-if="GoodsCategory != null && GoodsCategory.Id != ''">
					<span>分组ID</span>
					<em>
						{{GoodsCategory.Id}}
					</em>
				</li>
				
				<li>
					<span>分组名</span>
					<em  v-if="GoodsCategory != null">
						<el-input v-model="GoodsCategory.Name" style="width: 200px;"></el-input>
					</em>
				</li>
				
				
			
				<li>
					<span>分组状态</span>
					<em  v-if="GoodsCategory != null">
						<el-select placeholder="设置状态" v-model="GoodsCategory.Status">
							<el-option label="删除" :value="0" disabled></el-option>
							<el-option label="正常" :value="50"></el-option>
						    <el-option label="禁用" :value="10"></el-option>
					    </el-select>
					</em>
				</li>
				
				<li v-if="GoodsCategory != null && GoodsCategory.Id != 0">
					<span>创建时间</span>
					<em>
						{{GoodsCategory.CreatedAt}}
					</em>
				</li>
				
				<li v-if="GoodsCategory != null && GoodsCategory.Id != 0">
					<span>最后修改时间</span>
					<em>
						{{GoodsCategory.UpdatedAt}}
					</em>
				</li>
				
				<li>
					<span></span>
					<em>
						<el-button v-if="GoodsCategory != null && GoodsCategory.Id != ''" @click="UpdateGoodsCategory()" type="warning">提交修改</el-button>
						<el-button v-if="GoodsCategory != null && GoodsCategory.Id == ''" @click="UpdateGoodsCategory()" type="warning">添加分组</el-button>
					</em>
				</li>
			</div>
		</div>
		
		
	</div>
</template>

<script>
	import {Select,Option,Dialog,Upload} from 'element-ui'
	export default {
	  name: 'GoodsClassificationInfo',
	  props: {
	  },
	  data() {
	      return {
			  GoodsCategory:null,
			  FatherList:[],
	      }
	  },
	  components: {
		'el-select':Select,
		'el-option':Option,
		'el-dialog':Dialog,
		'el-upload':Upload,
	  },
	  created() {
		
	  	if(this.$route.params.Id == undefined){
			this.$message('缺少分组ID，页面无法工作')
			return
		}
		//this.GetFatherList()
		if(this.$route.params.Id == 'add'){
			this.InitGoodsCategory()
			return
		}
		this.GetGoodsCategory(this.$route.params.Id)
	  },
	  methods:{
		  InitGoodsCategory(){
			this.GoodsCategory = {
				Id:'',
				Name:'',
				Status:50,
				ParentId:0,
				Icon:''
			}
		  },
		  GetFatherList(_page){
		  		let that = this
		  		let data = {
		  			Service:'Shop',
		  			Class: 'GoodsClassification',
		  			Action: 'List',
		  			Page:1,
		  			PageSize:20,
					ShopId:that.$store.getters.getShopId, 
					FatherId:'' //提取顶级分组
		  		}
		  		this.$post(that.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){
		  				that.$message(res.Msg)
		  				return
		  			}
		  			
					that.FatherList = res.Data.GoodsClassificationList
		  			
		  		})
		  },
		    GetGoodsCategory(_id){
		  		let that = this
		  		let data = {Service:'Shop',Class: 'GoodsClassification',Action: 'Get',Id:_id,}
		  		this.$post(that.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){
		  				that.$message(res.Msg)
		  				return
		  			}
		  			that.GoodsCategory = res.Data
		  			
		  		})
		    },
			UpdateGoodsCategory(){
				let that = this
				let data = {Service:'Goods',Class: 'GoodsClassification',Action: 'Update',Id:that.GoodsCategory.Id,Status:that.GoodsCategory.Status,Name:that.GoodsCategory.Name,FatherId:that.GoodsCategory.FatherId,ShopId:that.$store.getters.getShopId}
				if(that.GoodsCategory.Id == 0){
					data.Action = 'Add'
					data.Id = ''
				}
				this.$post(that.$store.getters.getApiHost,data)
				.then((res) => {
					that.$message(res.Msg)
					if(res.ErrorId == 0){
						that.$Jump('/my/goods/goods_classification_list')
					}
				})
			},
	  }
	}
</script>

<style scoped>
.Brand{
	background-color: #FFFFFF;
	margin: 20px;
	padding: 20px;
}
.Brand .Top{
	display: flex;
	align-items: center;
}
.Brand .Top .Left{
	
}
.Brand .Top .Right{
	flex: 1;
	text-align: right;
	color: #999999;
}

.Brand .List{
	margin-top: 20px;	
}
.Brand .List .One{
	border: 1px solid rgba(0,0,0,0.04);
	padding: 20px;
	margin-bottom: 20px;
}
.Brand .List .One li{
	display: flex;
	line-height: 20px;
	padding: 10px 0px;
	align-items: center;
}
.Brand .List .One li span{
	width: 140px;
	color: rgba(0,0,0,0.3);
}
.Brand .List .One li  em{
	flex: 1;
}
.Brand .List .One li i{
	font-style: normal;
	color: rgba(0,0,0,0.2);
}


.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 64px;
    height: 64;
    line-height: 64px;
    text-align: center;
  }
  .avatar {
    width: 64px;
    height: 64px;
    display: block;
  }

</style>
